export const SAVE_FINANCIAL_WELLNESS_ANSWER = 'saveFinancialWellnessAnswer';

export const QUESTION_TYPE = {
  Sentiment: 'sentiment',
  Frequency: 'frequency',
};

export const QUESTION_TYPE_OPTIONS = {
  [QUESTION_TYPE.Sentiment]: {
    label: 'How well does this statement describe you or your situation?',
    options: ['Completely', 'Very well', 'Somewhat', 'Very little', 'Not at all'],
  },
  [QUESTION_TYPE.Frequency]: {
    label: 'How often does this statement apply to you?',
    options: ['Always', 'Often', 'Sometimes', 'Rarely', 'Never'],
  },
};

export const NUMBER_OF_ANSWER_OPTIONS = 5;
